import R from '../../lib/routes';
import { Button, BUTTON_VARIANTS } from '../widgets/Button';
import i18n from '../../translations/i18n';

const isServer = typeof window === 'undefined';
const WOW = !isServer ? require('../../../node_modules/wow.js/dist/wow') : null;

import './ZeroFeeDepositsOnCrypto.scss';

export default class ZeroFeeDepositsOnCrypto extends React.Component {
  componentDidMount() {
    new WOW().init();
  }

  render() {
    return (
      <section className="ZeroFeeDepositsOnCrypto row">
        <div className="container">
          <div className="col-12 align-self-center text-center wow animated zoomIn">
            <h2>{i18n.zeroFeeTitle}</h2>
            <p>{i18n.zeroFeeText}</p>
            <Button
              variant={BUTTON_VARIANTS.primary}
              onClick={() => (location.href = R.exchangeRegister)}
            >
              {i18n.zeroFeeBtn}
            </Button>
          </div>
        </div>
      </section>
    );
  }
}
