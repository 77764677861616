import * as React from 'react';
import R from '../../lib/routes';
import { Button, BUTTON_VARIANTS } from '../widgets/Button';
import { classNamer } from '../../lib/utils';
import i18n from '../../translations/i18n';

const isServer = typeof window === 'undefined';
const WOW = !isServer ? require('../../../node_modules/wow.js/dist/wow') : null;

const cn = classNamer('TrustedCryptoExchange');

import './TrustedCryptoExchange.scss';
export default class TrustedCryptoExchange extends React.Component {
  componentDidMount() {
    new WOW().init();
  }
  render() {
    return (
      <section className={`${cn()} row p-4 hasDarkBackground wow animated fadeInRightSmall`}>
        <div className="container ">
          <div className="col-12">
            <div
              className={`${cn(
                'content'
              )} align-self-top align-self-md-center text-lg-left wow animated fadeInLeftSmall`}
              data-wow-delay=".6s"
            >
              <h1>{i18n.homepageTitle}</h1>
              <p>
                {i18n.depositYourCrypto}
                <br />
                {i18n.getCryptoFast}
                <br />
                {i18n.buySimple}
              </p>

              <div id="btns-wrap">
                <Button
                  variant={BUTTON_VARIANTS.secondary}
                  onClick={() => (location.href = R.exchange)}
                >
                  {i18n.viewMarkets}
                </Button>
                <Button
                  variant={BUTTON_VARIANTS.primary}
                  id="sign-in"
                  onClick={() => (location.href = R.exchangeRegister)}
                  style={{ textTransform: 'uppercase' }}
                >
                  {i18n.register}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
