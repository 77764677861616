import React from 'react';
import * as PropTypes from 'prop-types';
import { classes, classNamer } from '../../lib/utils';

import './Link.scss';

const cn = classNamer('Link');

export const Link = ({ href, className, children }) => {
  return (
    <a href={href} className={classes(cn(), className + '-link')}>
      {children}
    </a>
  );
};

Link.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
};
