import { Link } from '../../router';
import R from '../../lib/routes';
import i18n from '../../translations/i18n';

const isServer = typeof window === 'undefined';
const WOW = !isServer ? require('../../../node_modules/wow.js/dist/wow') : null;

import './ThreeFeatures.scss';

export default class ThreeFeatures extends React.Component {
  componentDidMount() {
    new WOW().init();
  }

  render() {
    return (
      <section className="ThreeFeatures">
        <div className="container">
          <div className="ThreeFeatures-bot-friendly col-lg-4 col-md-12 d-flex left-section wow animated fadeInUpSmall">
            <img className="mb-4" src="/static/icons/horizontal-api.png" alt="" />
            <h4>{i18n.tradingBotTitle}</h4>
            <p>
              {i18n.tradingBotTextOne}{' '}
              <Link href={R.apiDocumentation}>
                <a target="_blank">{i18n.tradingBotTextTwo}</a>
              </Link>{' '}
              {i18n.tradingBotTextThree}
            </p>
          </div>

          <div
            className="ThreeFeatures-fast col-lg-4 col-md-12 align-self-center d-flex text-center wow animated fadeInUpSmall"
            data-wow-delay=".3s"
          >
            <img className="mb-4" src="/static/icons/customer-support.png" alt="Customer support" />
            <h4>{i18n.ultraFastTitle}</h4>
            <p>{i18n.ultraFastText}</p>
          </div>

          <div
            className="ThreeFeatures-secure col-lg-4 col-md-12 d-flex text-center right-section wow animated fadeInUpSmall"
            data-wow-delay=".6s"
          >
            <img
              className="mb-4"
              src="/static/icons/trade-at-the-speed-of-light.png"
              alt="Trade at the speed of light"
            />
            <h4>{i18n.firstGradeTitle}</h4>

            <p className="nowrap">{i18n.firstGradeTextOne},</p>
            <p>{i18n.firstGradeTextTwo},</p>
            <p>{i18n.firstGradeTextThree}.</p>
          </div>
        </div>
      </section>
    );
  }
}
