import R from '../../lib/routes';
import { Link } from '../../router';
import i18n from '../../translations/i18n';

import './GlobalReliableExchange.scss';

const isServer = typeof window === 'undefined';
const WOW = !isServer ? require('wow.js') : null;

export default class GlobalReliableExchange extends React.Component {
  componentDidMount() {
    new WOW().init();
  }
  render() {
    return (
      <section className="GlobalReliableExchange">
        <div className="container">
          <div className="col-lg-4 col-md-12 align-self-center text-center wow animated fadeInUpSmall">
            <img className="mb-4" src="/static/icons/free-to-sign-up.png" alt="free-to-sign-up" />
            <h4>{i18n.globalReachTitle}</h4>
            <p className="nowrap">{i18n.globalReachTextOne}.</p>
            <p>
              {i18n.globalReachTextTwo}{' '}
              <Link href={R.countriesList}>
                <a>{i18n.globalReachTextThree}</a>
              </Link>
              .
            </p>
          </div>
          <div
            className="superior-infrastructure col-lg-4 col-md-12 no-gutters align-self-center text-center wow animated fadeInUpSmall"
            data-wow-delay=".3s"
          >
            <img
              className="mb-4"
              src="/static/icons/superior-infrastructure.png"
              alt="superior-infrastructure"
            />
            <h4>{i18n.selectiveAssetTitle}</h4>
            <p>{i18n.selectiveAssetText}</p>
          </div>
          <div
            className="col-lg-4 col-md-12 no-gutters align-self-center text-center wow animated fadeInUpSmall"
            data-wow-delay=".6s"
          >
            <img className="mb-4" src="/static/icons/secure-haven.png" alt="secure-haven" />
            <h4>{i18n.reliableSupportTitle}</h4>
            <p className="nowrap">{i18n.reliableSupportTextOne}.</p>
            <p className="nowrap">{i18n.reliableSupportTextTwo}.</p>
          </div>
        </div>
      </section>
    );
  }
}
