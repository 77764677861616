import './ManagedFeature.scss';
import { Button, BUTTON_VARIANTS } from '../widgets/Button';
import R from '../../lib/routes';
import { Link } from '../widgets/Link';

export default class ManagedFeature extends React.Component {
  render() {
    return (
      <section className="ManagedFeature">
        <div className="container">
          <div>
            <img src="/static/swiss_coin.jpg" alt="Bitcoin with Swiss Flag" />
          </div>
          <div>
            <p>
              An additional and separate service called Managed, for those who qualify, is offered by Xcalibra AG,
              where you can buy and sell Bitcoin, Ethereum and many other cryptocurrencies
              using wire transfers in EUR and CHF. Learn more how you can begin positioning yourself
              within cryptocurrency and blockchain technology.
            </p>
          </div>
          <div>
            <p>
              <Button
                variant={BUTTON_VARIANTS.primary}
                onClick={() => (location.href = `https://xcalibra.com/managed`)}
              >
                Learn More About Managed
              </Button>
            </p>
          </div>
        </div>
      </section>
    );
  }
}
