import React from 'react';
import Head from 'next/head';

import Layout from '../src/components/layout/Layout';
import TrustedCryptoExchange from '../src/components/homepage/TrustedCryptoExchange';
import SmoothPerformance from '../src/components/homepage/SmoothPerformance';
import GlobalReliableExchange from '../src/components/homepage/GlobalReliableExchange';
import ThreeFeatures from '../src/components/homepage/ThreeFeatures';
import ZeroFeeDepositsOnCrypto from '../src/components/homepage/ZeroFeeDepositsOnCrypto';
import i18n from '../src/translations/i18n';
import ManagedFeature from '../src/components/homepage/ManagedFeature';

const Index = props => {
  return (
    <>
      <Head>
        <title>{i18n.homepageSEOTitle}</title>
        <meta name="description" content={i18n.homepageSEODescription} key="description" />
      </Head>
      <Layout {...props}>
        <TrustedCryptoExchange />
        <GlobalReliableExchange />
        <SmoothPerformance />
        <ThreeFeatures />
        <ManagedFeature />
        <ZeroFeeDepositsOnCrypto />
      </Layout>
    </>
  );
};

export default Index;
