import i18n from '../../translations/i18n';

const isServer = typeof window === 'undefined';
const WOW = !isServer ? require('../../../node_modules/wow.js/dist/wow') : null;

import './SmoothPerformance.scss';

export default class SmoothPerformance extends React.Component {
  componentDidMount() {
    new WOW().init();
  }

  render() {
    return (
      <section className="SmoothPerformance hasDarkBackground wow animated fadeInLeftSmall">
        <div className="container">
          <div className="col-12 align-self-top align-self-md-center text-xs-left text-lg-center mt-4">
            <div
              className="SmoothPerformance-content wow animated fadeInRightSmall"
              data-wow-delay=".6s"
            >
              <h3>{i18n.smoothPerformanceTitle}</h3>
              <p>{i18n.smoothPerformanceText}</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
